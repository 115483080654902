import { Howl, Howler } from "howler";
import * as basicLightbox from "basiclightbox";
import ProgressBar from "progressbar.js";
import Visibility from "visibilityjs";

import overlay2000s from "../../assets/2000soverlay.png";
import overlay1990s from "../../assets/90soverlay.png";
import overlay1980s from "../../assets/80soverlay.png";

import sound2000MP3 from "../../assets/audio/2000s.mp3";
import sound1990MP3 from "../../assets/audio/1990s.mp3";
import sound1980MP3 from "../../assets/audio/1980s.mp3";
import sound2000Webm from "../../assets/audio/2000s.webm";
import sound1990Webm from "../../assets/audio/1990s.webm";
import sound1980Webm from "../../assets/audio/1980s.webm";
import quizData1980 from "./quizData1980.js";
import quizData1990 from "./quizData1990.js";
import quizData2000 from "./quizData2000.js";

const audio80s = new Howl({
  src: [sound1980Webm, sound1980MP3],
  sprite: {
    1: [0, 10000],
    2: [10000, 10000],
    3: [20000, 10000],
    4: [30000, 10000],
    5: [40000, 10000],
    6: [50000, 10000],
    7: [60000, 10000],
    8: [70000, 10000],
    9: [80000, 10000],
    10: [90000, 10000]
  }
});

const audio90s = new Howl({
  src: [sound1990Webm, sound1990MP3],
  sprite: {
    1: [0, 10000],
    2: [10000, 10000],
    3: [20000, 10000],
    4: [30000, 10000],
    5: [40000, 10000],
    6: [50000, 10000],
    7: [60000, 10000],
    8: [70000, 10000],
    9: [80000, 10000],
    10: [90000, 10000]
  }
});

const audio2000s = new Howl({
  src: [sound2000Webm, sound2000MP3],
  sprite: {
    1: [0, 10000],
    2: [10000, 10000],
    3: [20000, 10000],
    4: [30000, 10000],
    5: [40000, 10000],
    6: [50000, 10000],
    7: [60000, 10000],
    8: [70000, 10000],
    9: [80000, 10000],
    10: [90000, 10000]
  }
});

class QuizPlayer {
  constructor(target) {
    this.target = document.querySelector(target);
    this.answerWrapper = this.target.querySelector(".quiz-player__answers");
    this.progressWrapper = this.target.querySelector(
      ".quiz-player__video-area-progress"
    );
    this.answerContainers = this.target.querySelectorAll(
      ".quiz-player__answer"
    );
    this.answers = this.target.querySelectorAll(".quiz-player__answer-text");
    this.questionCounter = this.target.querySelector(
      ".quiz-player__sound-text"
    );
    this.eraWrapper = this.target.querySelector(".quiz-player__era");
    this.overlayCharacter = this.target.querySelector(
      ".quiz-player__character-overlay"
    );
    this.postQuizContent = this.target.querySelector(".quiz-player__post-quiz");
    this.targetAudioElements = this.target.querySelectorAll("audio");
    this.replayButton = this.target.querySelector(".quiz-player__replay");
    this.finalScore = this.target.querySelector(
      ".quiz-player__post-quiz-score"
    );
    this.replayQuizButton = this.target.querySelector(
      ".quiz-player__post-quiz-replay-button"
    );
    this.eraButtons = this.target.querySelectorAll(".quiz-player__version-btn");
    this.startButton = this.target.querySelector(".quiz-player__start-btn");
    this.form = this.target.querySelector(".quiz-player__submit-form");
    this.termsOpen = this.target.querySelector(
      ".quiz-player__submit-form-terms-open"
    );
    this.thankYouSection = this.target.querySelector(".quiz-player__thank-you");
    this.thankYouReplayButtons = this.target.querySelectorAll(
      ".quiz-player__thank-you-button"
    );
    // default to 2000s quiz
    this.quizData = {};
    this.sound;

    this.circle = new ProgressBar.Circle("#progress", {
      color: "#41B6F8",
      duration: 10000,
      easing: "linear",
      strokeWidth: 4,
      trailColor: "rgba(0,0,0,0.33)"
    });

    this.questionInc = 0;
    this.currentAudioElement = this.targetAudioElements[this.questionInc];
    this.currentQuestion = {};
    this.questionIntervalDur = 10000;
    this.lastQuiz = "";
    this.lastEraTarget = this.target.querySelector(
      ".quiz-player__version-btn--2000"
    );
    this.questionInterval;
    this.hasAnsweredCurrent = false;
    this.score = 0;
    this.playing = false;

    this.visibilityListener = Visibility.change((e, state) => {
      if (state === "hidden") {
        this.sound.mute(true);
      } else if (state === "visible") {
        this.sound.mute(false);
      }
    });
  }

  init() {
    this.replayButton.addEventListener("click", () => {
      this.playSound();
    });

    this.answerContainers.forEach(container => {
      container.addEventListener("click", e => {
        const { currentTarget } = e;
        this.questionAnswer(currentTarget);
      });
    });

    this.eraButtons.forEach(button => {
      button.addEventListener("click", e => {
        const { currentTarget } = e;
        const era = currentTarget.getAttribute("data-era");
        this.lastEraTarget = currentTarget;
        this.start(era);
      });
    });

    this.startButton.addEventListener("click", () => {
      this.start("2000");
    });

    this.replayQuizButton.addEventListener("click", () => {
      this.start(this.lastQuiz);
    });

    this.form.addEventListener("submit", e => {
      this.formSubmit(e);
    });

    this.termsOpen.addEventListener("click", e => {
      e.preventDefault();
      this.openTerms();
    });

    this.thankYouReplayButtons.forEach(button => {
      button.addEventListener("click", e => {
        const { currentTarget } = e;
        const era = currentTarget.getAttribute("data-era");
        if (era === "80") {
          this.lastEraTarget = document.querySelector(
            ".quiz-player__version-btn--80"
          );
        } else if (era === "90") {
          this.lastEraTarget = document.querySelector(
            ".quiz-player__version-btn--90"
          );
        } else {
          this.lastEraTarget = document.querySelector(
            ".quiz-player__version-btn--2000"
          );
        }
        this.start(era);
      });
    });
  }

  start(era) {
    this.reset();
    this.eraButtons.forEach(el => {
      el.querySelector(".quiz-player__version-btn-bottom-text").innerHTML =
        "START CHALLENGE";
      el.classList.remove("quiz-player__version-btn--inactive");
      if (el !== this.lastEraTarget) {
        el.classList.add("quiz-player__version-btn--inactive");
      }
    });
    this.lastEraTarget.querySelector(
      ".quiz-player__version-btn-bottom-text"
    ).innerHTML = "CURRENTLY ACTIVE";
    this.loadQuiz(era);
    this.progressWrapper.classList.remove(
      "quiz-player__video-area-progress--inactive"
    );
    this.answerWrapper.classList.remove("quiz-player__answers--inactive");
    this.startButton.classList.add("quiz-player__start-btn--inactive");
    this.postQuizContent.classList.add("quiz-player__post-quiz--inactive");
    this.thankYouSection.classList.add("quiz-player__thank-you--inactive");
    this.playing = true;
    this.triggerQuestion();
    this.lastQuiz = era;

    gtag("event", "Quiz Started", {
      event_category: "Quiz",
      event_label: era,
      value: ""
    });
  }

  loadQuiz(era) {
    this.eraWrapper.className = "quiz-player__era";
    switch (era) {
      case "80":
        this.eraWrapper.querySelector(".quiz-player__era-text").innerHTML =
          "80s EDITION";
        this.eraWrapper.classList.add("quiz-player__era--80");
        this.overlayCharacter.src = overlay1980s;
        this.quizData = quizData1980;
        this.sound = audio80s;
        break;
      case "90":
        this.eraWrapper.querySelector(".quiz-player__era-text").innerHTML =
          "90s EDITION";
        this.eraWrapper.classList.add("quiz-player__era--90");
        this.quizData = quizData1990;
        this.overlayCharacter.src = overlay1990s;
        this.sound = this.sound = audio90s;
        break;
      default:
        this.eraWrapper.querySelector(".quiz-player__era-text").innerHTML =
          "2000s EDITION";
        this.eraWrapper.classList.add("quiz-player__era--2000");
        this.quizData = quizData2000;
        this.overlayCharacter.src = overlay2000s;
        this.sound = audio2000s;
    }
  }

  triggerQuestion() {
    if (this.questionInc > 9) {
      this.end();
      return;
    }
    this.circle.set(0);
    this.circle.animate(1);
    this.playSound();
    this.questionCounter.innerHTML = `Sound clip #${this.questionInc + 1}`;
    this.applyAnswerHTML();
    this.currentQuestion = this.quizData.questions[this.questionInc];
    this.hasAnsweredCurrent = false;
    // clear prev timeout
    clearTimeout(this.questionInterval);
    this.questionInterval = setTimeout(() => {
      this.incrementCounter();
      this.triggerQuestion();
    }, 10000);
  }

  playSound() {
    const soundIndex = (this.questionInc + 1).toString();
    this.sound.stop();
    this.sound.play(soundIndex);
  }

  incrementCounter() {
    this.questionInc++;
  }

  questionAnswer(target) {
    if (this.hasAnsweredCurrent) return;
    if (this.checkIsCorrect(target)) {
      this.score++;
    }
    this.hasAnsweredCurrent = true;
    this.incrementCounter();
    this.triggerQuestion();

    gtag("event", "Question Answered", {
      event_category: "Quiz",
      event_label: this.quizData.era,
      value: ""
    });
  }

  checkIsCorrect(target) {
    const index = parseInt(target.getAttribute("data-index")) - 1;
    const { answers } = this.currentQuestion;
    if (answers[index].correct === true) return true;

    return false;
  }

  applyAnswerHTML() {
    this.answers.forEach((answer, index) => {
      answer.innerHTML = this.quizData.questions[this.questionInc].answers[
        index
      ].answerText;
    });
  }

  reset() {
    if (this.sound !== undefined) {
      this.sound.stop();
    }
    this.circle.set(0);
    this.score = 0;
    this.currentQuestion = {};
    this.questionInc = 0;
    clearInterval(this.questionInterval);
  }

  end() {
    this.sound.stop();
    this.circle.set(0);
    clearInterval(this.questionInterval);
    this.answerWrapper.classList.add("quiz-player__answers--inactive");
    this.progressWrapper.classList.add(
      "quiz-player__video-area-progress--inactive"
    );
    this.postQuizContent.classList.remove("quiz-player__post-quiz--inactive");
    this.finalScore.innerHTML = `You Scored: ${this.score}/10`;

    gtag("event", "Quiz Ended", {
      event_category: "Quiz",
      event_label: this.quizData.era,
      value: ""
    });
  }

  formSubmit(e) {
    e.preventDefault();
    const data = new FormData();

    const email = this.form.querySelector("#email").value;

    let quizId;

    if (this.quizData.era === 1980) {
      quizId = 56;
    } else if (this.quizData.era === 1990) {
      quizId = 57;
    } else {
      quizId = 58;
    }

    data.append("score", this.score);
    data.append("quiz_id", quizId);
    data.append("option", "0");
    data.append("contact", "1");
    data.append("username", "n/a");
    data.append("email", email);
    data.append("answers", "n/a");
    // post off to gquiz
    fetch("https://gquiz.gamer-network.net/score", {
      method: "POST",
      body: data
    })
      .then(function(data) {})
      .catch(function(e) {
        console.log(e);
      });

    this.postQuizContent.classList.add("quiz-player__post-quiz--inactive");
    this.showEndSection();
  }

  openTerms() {
    basicLightbox
      .create(
        `
      <ul>
        <li>Competition is open to UK, US, and CA entrants only</li>
        <li>Entrants must be aged 18yrs or older</li>
        <li>Employees of Gamer Network and Logitech + members of their family, are not permitted to enter</li>
        <li>The competition ends 31st August 2019</li>
        <li>The judge's decision is final</li>
        <li>By entering the competition, you agree for us to contact you via the email you submit</li>
        <li>Under no circumstances will the prize be swapped for cash or other consideration</li>
        <li>Details will only be used to contact the contestants. All other data will be deleted by Gamer Network immediately thereafter</li>
        <li>This competition is being organised and run in accordance with industry standards and the applicable law of England and Wales</li>
      </ul>
    `
      )
      .show();
  }

  showEndSection() {
    this.thankYouSection.classList.remove("quiz-player__thank-you--inactive");
  }
}

const quizPlayer = new QuizPlayer(".quiz-player");

quizPlayer.init();
