export default {
  era: 2000,
  questions: [
    {
      id: 1,
      answers: [
        {
          answerText: "Counter Strike",
          correct: true
        },
        {
          answerText: "Ghost Recon",
          correct: false
        },
        {
          answerText: "Battlefield 2",
          correct: false
        }
      ]
    },
    {
      id: 2,
      answers: [
        {
          answerText: "Mass Effect",
          correct: false
        },
        {
          answerText: "Gears of War",
          correct: false
        },
        {
          answerText: "Halo",
          correct: true
        }
      ]
    },
    {
      id: 3,
      answers: [
        {
          answerText: "The Sims",
          correct: true
        },
        {
          answerText: "Animal Crossing",
          correct: false
        },
        {
          answerText: "Civilization 4",
          correct: false
        }
      ]
    },
    {
      id: 4,
      answers: [
        {
          answerText: "Wolfenstein",
          correct: false
        },
        {
          answerText: "Max Payne",
          correct: true
        },
        {
          answerText: "GTA 4",
          correct: false
        }
      ]
    },
    {
      id: 5,
      answers: [
        {
          answerText: "Modern Warfare 2",
          correct: true
        },
        {
          answerText: "Call of Duty 2",
          correct: false
        },
        {
          answerText: "Battlefield 4",
          correct: false
        }
      ]
    },
    {
      id: 6,
      answers: [
        {
          answerText: "Fallout 3",
          correct: false
        },
        {
          answerText: "Crysis",
          correct: true
        },
        {
          answerText: "Dead Space",
          correct: false
        }
      ]
    },
    {
      id: 7,
      answers: [
        {
          answerText: "EVE Online",
          correct: false
        },
        {
          answerText: "Half-Life 2",
          correct: false
        },
        {
          answerText: "Portal",
          correct: true
        }
      ]
    },
    {
      id: 8,
      answers: [
        {
          answerText: "Spore",
          correct: false
        },

        {
          answerText: "Diablo 2",
          correct: false
        },
        {
          answerText: "Minecraft",
          correct: true
        }
      ]
    },
    {
      id: 9,
      answers: [
        {
          answerText: "Sniper Elite",
          correct: false
        },
        {
          answerText: "Team Fortress 2",
          correct: true
        },
        {
          answerText: "Splinter Cell",
          correct: false
        }
      ]
    },
    {
      id: 10,
      answers: [
        {
          answerText: "Left 4 Dead",
          correct: true
        },
        {
          answerText: "Call of Duty Zombies",
          correct: false
        },
        {
          answerText: "Manual Samuel",
          correct: false
        }
      ]
    }
  ]
};
