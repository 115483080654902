export default {
  era: 1990,
  questions: [
    {
      id: 1,
      answers: [
        {
          answerText: "Tekken 2",
          correct: false
        },
        {
          answerText: "Soul Calibur",
          correct: false
        },
        {
          answerText: "Mortal Kombat",
          correct: true
        }
      ]
    },
    {
      id: 2,
      answers: [
        {
          answerText: "Shadow Warrior",
          correct: false
        },
        {
          answerText: "Doom",
          correct: true
        },
        {
          answerText: "Rise of Triad",
          correct: false
        }
      ]
    },
    {
      id: 3,
      answers: [
        {
          answerText: "Thief: The Dark Project",
          correct: true
        },
        {
          answerText: "Daggerfall",
          correct: false
        },
        {
          answerText: "Terminator: Future Shock",
          correct: false
        }
      ]
    },
    {
      id: 4,
      answers: [
        {
          answerText: "System Shock 2",
          correct: false
        },
        {
          answerText: "Quake",
          correct: true
        },
        {
          answerText: "Wolfenstein 3D",
          correct: false
        }
      ]
    },
    {
      id: 5,
      answers: [
        {
          answerText: "Command & Conquer",
          correct: true
        },
        {
          answerText: "The Settlers 2",
          correct: false
        },
        {
          answerText: "Age of Empires 2",
          correct: false
        }
      ]
    },
    {
      id: 6,
      answers: [
        {
          answerText: "Starcraft 1",
          correct: false
        },
        {
          answerText: "UFO: Enemy Unknown",
          correct: false
        },
        {
          answerText: "Oddworld",
          correct: true
        }
      ]
    },
    {
      id: 7,
      answers: [
        {
          answerText: "Grand Theft Auto",
          correct: true
        },
        {
          answerText: "Theme Hospital",
          correct: false
        },
        {
          answerText: "Worms",
          correct: false
        }
      ]
    },
    {
      id: 8,
      answers: [
        {
          answerText: "Half-Life",
          correct: true
        },
        {
          answerText: "Star Trek: 25th Anniversary",
          correct: false
        },
        {
          answerText: "Alien Carnage",
          correct: false
        }
      ]
    },
    {
      id: 9,
      answers: [
        {
          answerText: "Descent 2",
          correct: false
        },
        {
          answerText: "Unreal Tournament",
          correct: true
        },
        {
          answerText: "Quake",
          correct: false
        }
      ]
    },
    {
      id: 10,
      answers: [
        {
          answerText: "Waterworld",
          correct: false
        },
        {
          answerText: "Theme Park",
          correct: false
        },
        {
          answerText: "Roller Coaster Tycoon",
          correct: true
        }
      ]
    }
  ]
};
