import "nodelist-foreach-polyfill";
import "whatwg-fetch";
import "promise-polyfill/src/polyfill";
import "array-from-polyfill";
import * as basicLightbox from "basiclightbox";

import "./js/player/quizPlayer";

import "../node_modules/basiclightbox/dist/basicLightbox.min.css";
import "./css/main.css";

import lgVideo from "../src/assets/g432-sizzle-2.mp4";

document.querySelector(".video-section video").src = lgVideo;

document.querySelector(".video-section").addEventListener("click", e => {
  e.preventDefault();
  basicLightbox
    .create(document.querySelector(".overlay-video-template"))
    .show();

  gtag("event", "Click Video", {
    event_category: "Video Player",
    event_label: "",
    value: ""
  });
});

const galleryItems = document.querySelectorAll(
  ".products__product-gallery-item"
);

const galleryTitle = document.querySelector(".products__heading");
const galleryText = document.querySelector(".products__text");

const galleryContent = [
  {
    title: "ONE HEADSET, ALL PLATFORMS",
    text:
      "Your headset works with your PC, PlayStation 4, or docked Nintendo Switch via included USB DAC. You can also game on consoles or mobile devices via 3.5 mm cable."
  },
  {
    title: "DTS HEADPHONE:X 2.0",
    text:
      "Next-gen DTS Headphone:X 2.0 surround sound powered by Logitech G HUB software, lets you hear enemies sneaking behind you, special ability cues, and immersive environments—all around you. Experience 3D audio that goes beyond 7.1 channels to make you feel like you’re right in the middle of the action."
  },
  {
    title: "50 MM DRIVERS",
    text:
      "Big 50 mm audio drivers produce a complete, expansive sound for a more immersive gaming experience. Your favorite games will finally sound the way they’re supposed to: Amazing."
  },
  {
    title: "BUILT FOR COMFORT AND ENDURANCE",
    text:
      "Everything about these headphones is about comfort. The deluxe lightweight leatherette ear cups and headband are made to keep pressure off your ears. Ear cups rotate up to 90 degrees for convenience. Adjust volume on any platform with the ear-cup mounted volume wheel."
  }
];

galleryItems.forEach(item => {
  item.addEventListener("click", e => {
    galleryItems.forEach(item => {
      item.querySelector(".gallery-item-overlay").style.opacity = 0;
    });
    const { currentTarget } = e;
    const index = parseInt(currentTarget.getAttribute("data-index"));

    galleryTitle.innerHTML = galleryContent[index].title;
    galleryText.innerHTML = galleryContent[index].text;

    currentTarget.querySelector(".gallery-item-overlay").style.opacity = 1;

    gtag("event", "Click Gallery Image", {
      event_category: "Gallery",
      event_label: index,
      value: ""
    });
  });
});

const articleCards = document.querySelectorAll(".intro__card");

articleCards.forEach(el => {
  el.addEventListener("click", e => {
    const { currentTarget } = e;
    const url = currentTarget.getAttribute("data-url");
    gtag("event", "Click Product Card", {
      event_category: "Product Cards",
      event_label: url,
      value: ""
    });
    window.open(url, "_blank");
  });
});

// polyfill object.assign
if (typeof Object.assign !== "function") {
  // Must be writable: true, enumerable: false, configurable: true
  Object.defineProperty(Object, "assign", {
    value: function assign(target, varArgs) {
      // .length of function is 2
      "use strict";
      if (target === null || target === undefined) {
        throw new TypeError("Cannot convert undefined or null to object");
      }

      var to = Object(target);

      for (var index = 1; index < arguments.length; index++) {
        var nextSource = arguments[index];

        if (nextSource !== null && nextSource !== undefined) {
          for (var nextKey in nextSource) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
      }
      return to;
    },
    writable: true,
    configurable: true
  });
}
