export default {
  era: 1980,
  questions: [
    {
      id: 1,
      answers: [
        {
          answerText: "Super Mario Bros",
          correct: false
        },
        {
          answerText: "Space Quest",
          correct: false
        },
        {
          answerText: "Pac-man",
          correct: true
        }
      ]
    },
    {
      id: 2,
      answers: [
        {
          answerText: "Frogger",
          correct: false
        },
        {
          answerText: "Manic Miner",
          correct: true
        },
        {
          answerText: "Dig Dug",
          correct: false
        }
      ]
    },
    {
      id: 3,
      answers: [
        {
          answerText: "Elite",
          correct: true
        },
        {
          answerText: "Castlevania",
          correct: false
        },
        {
          answerText: "Galaga",
          correct: false
        }
      ]
    },
    {
      id: 4,
      answers: [
        {
          answerText: "Jet Set Willy",
          correct: true
        },
        {
          answerText: "Robotron 2084",
          correct: false
        },
        {
          answerText: "Q*bert",
          correct: false
        }
      ]
    },
    {
      id: 5,
      answers: [
        {
          answerText: "Tempest",
          correct: false
        },
        {
          answerText: "Tetris",
          correct: true
        },
        {
          answerText: "Ghouls'n Goblins",
          correct: false
        }
      ]
    },
    {
      id: 6,
      answers: [
        {
          answerText: "Gauntlet",
          correct: true
        },
        {
          answerText: "Contra",
          correct: false
        },
        {
          answerText: "Defender",
          correct: false
        }
      ]
    },
    {
      id: 7,
      answers: [
        {
          answerText: "Jungle Hunt",
          correct: false
        },
        {
          answerText: "Mappy",
          correct: false
        },
        {
          answerText: "Paper Boy",
          correct: true
        }
      ]
    },
    {
      id: 8,
      answers: [
        {
          answerText: "Legend of Zelda",
          correct: true
        },
        {
          answerText: "Marios Bros",
          correct: false
        },
        {
          answerText: "Donkey Kong",
          correct: false
        }
      ]
    },
    {
      id: 9,
      answers: [
        {
          answerText: "Prince of Persia",
          correct: true
        },
        {
          answerText: "Dragonslayer",
          correct: false
        },
        {
          answerText: "Joust",
          correct: false
        }
      ]
    },
    {
      id: 10,
      answers: [
        {
          answerText: "Dragon Quest",
          correct: false
        },
        {
          answerText: "Mega Man",
          correct: false
        },
        {
          answerText: "King's Quest IV",
          correct: true
        }
      ]
    }
  ]
};
